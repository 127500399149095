<template>
  <CRow>
    <CCol sm="12">
      <CCard>
        <CCardHeader>
          <CIcon name="cil-grid"/>
          クーポン選択 {{ this.store.name }}
        </CCardHeader>
        <CCardBody>
          <CDataTable
            :items="coupons"
            :fields="fields"
            column-filter
            table-filter
            items-per-page-select
            :items-per-page="10"
            hover
            sorter
            pagination
          >
            <template #status="{item}">
              <td class="text-right">
                <CBadge :color="getBadge(item.status)">
                  {{getStatusText(item.status)}}
                </CBadge>
              </td>
            </template>

            <template #discount="{item}">
              <td class="text-right">
                {{item.discount}}
              </td>
            </template>

            <template #coupon_code="{item}">
              <td>
                {{("000000" + item.couponCode).slice(-6)}}
              </td>
            </template>

            <template #maker_id="{item}">
              <td class="text-right">
                {{getMakerName(item.maker_id)}}
              </td>
            </template>

            <template #start_date="{item}">
              <td class="text-right">
                {{item.start_date}}
              </td>
            </template>

            <template #end_date="{item}">
              <td class="text-right">
                {{item.end_date}}
              </td>
            </template> 

            <template #is_valid="{item}">
              <td class="py-2 text-right">
                <CButton
                  :color="item.is_valid ? 'danger' : 'secondary'"
                  square
                  size="sm"
                  @click="toggleCoupon(item.id)"
                >
                  {{item.is_valid ? '有効' : '無効'}}
                </CButton>
              </td>
            </template>
            <template #details="{item, index}">
              <CCollapse :show="details.includes(index)">
                <CCardBody>
                  {{index + 1}} - {{item}}
                </CCardBody>
              </CCollapse>
            </template>
          </CDataTable>
        </CCardBody>
      </CCard>
    </CCol>
  </CRow>
</template>

<script>
import firebase from '@firebase/app';
import {CouponStatus} from '@/common/const';

const fields = [
  { key: 'title', label: 'タイトル',  _style:'width:25%; text-align:center;' },
  { key: 'coupon_code', label: 'クーポン番号', _style:'width:10%; text-align:center;' },
  { key: 'jan_code', label: 'JANコード', _style:'width:10%; text-align:center;' },
  { key: 'discount', label: '割引金額', _style:'width:10%; text-align:center;' },
  { key: 'maker_id', label: '発行メーカー', _style:'width:15%; text-align:center;' },
  { key: 'start_date', label: '開始日', _style:'width:15%; text-align:center;' },
  { key: 'end_date', label: '終了日', _style:'width:15%; text-align:center;' },
  { key: 'status', label: '公開', _style:'width:10%; text-align:center;min-width: 65px;' },
  { key: 'is_valid', label: '状態', _style:'width:10%; text-align:center;min-width: 75px;' },
]

export default {
  name: 'AdvancedTables',
  data () {
    return {
      coupons: [],
      fields,
      details: [],
      makers: [],
      originalStore: {
        coupons: [],
      },
      store: null,
      storeCoupons: [],
    }
  },
  methods: {
    getBadge (status) {
      return status === 1 ? 'success'
             : status === 2 ? 'secondary'
             : status === 3 ? 'warning'
             : status === 'Banned' ? 'danger' : 'primary'
    },
    getStatusText (status) {
      return status === CouponStatus.OPEN ? '公開'
             : status === CouponStatus.PRIVATE ? '非公開'
             : status === CouponStatus.CLOSE ? '終了' : 'エラー'
    },
    async toggleCoupon (couponId) {
      //this.$store.commit("showLoading")

      var targetCoupon = this.coupons.find((v) => v.id === couponId)
      if (this.isValid(couponId)) {
        targetCoupon.is_valid = false
        this.storeCoupons = this.storeCoupons.filter(coupon => coupon.id != couponId)
        firebase.firestore().collection('stores').doc(this.store.id).collection('storeCoupons').doc(couponId).delete()
      } else {
        // 同じjan_codeのクーポンは同一店舗で同一期間で共存できないので古いものを削除する
        var coupon = await firebase.firestore().collection('coupons').doc(couponId).get()
        var sameJanCodeCoupons = await firebase.firestore().collection('coupons').where('jan_code', '==', coupon.get('jan_code')).get()
        for (var sameJanCodeCoupon of sameJanCodeCoupons.docs) {
          var startDate1 = coupon.get('start_date') != null ? coupon.get('start_date').toDate() : null;
          var endDate1 = coupon.get('end_date') != null ? coupon.get('end_date').toDate() : null;
          var startDate2 = sameJanCodeCoupon.get('start_date') != null ? sameJanCodeCoupon.get('start_date').toDate() : null;
          var endDate2 = sameJanCodeCoupon.get('end_date') != null ? sameJanCodeCoupon.get('end_date').toDate() : null;
          if(this.isDateOverlap(startDate1, endDate1, startDate2, endDate2)) {
            firebase.firestore().collection('stores').doc(this.store.id).collection('storeCoupons').doc(sameJanCodeCoupon.id).delete()
            var oldTargetCoupon = this.coupons.find((v) => v.id === sameJanCodeCoupon.id)
            if (oldTargetCoupon != null) {
              oldTargetCoupon.is_valid = false
            }
          }
        }
        targetCoupon.is_valid = true
        let tmp = {}
        tmp.id = couponId
        this.storeCoupons.push(tmp)
        firebase.firestore().collection('stores').doc(this.store.id).collection('storeCoupons').doc(couponId).set(tmp)
      }

    },
    init () {
      // 初期化
      this.store = JSON.parse(JSON.stringify(this.originalStore))
      this.coupons = []
      this.storeCoupons = []

      this.fetch()
    },
    async fetch () {
      const id = this.$route.params.id

      // 店舗情報取得
      const storesDoc = await firebase.firestore().collection('stores').doc(id).get()
      this.store = storesDoc.data()
      const storeCoupons = await firebase.firestore().collection('stores').doc(id).collection('storeCoupons').get()
      storeCoupons.docs.map(doc => {
        this.storeCoupons.push(doc.data());
      });

      // メーカー一覧取得
      const makerNum = 2
      firebase.firestore().collection('users').where("role", "==", makerNum).get().then(function(result) {
        let tmp = []
        result.forEach(function(doc) {
          tmp.push(doc.data())
        })
        this.makers = tmp
      }.bind(this))

      let distributionId = firebase.auth().currentUser.uid
      const userDoc = await firebase.firestore().collection('users').doc(distributionId).get()
      const parent_id = userDoc.data().parent_id
      if (parent_id) {
        distributionId = parent_id;
      }

      // クーポン一括取得
      firebase.firestore().collection('coupons').where("status", "==", CouponStatus.OPEN).get().then(function(result) {
        let tmp = []
        result.forEach(function(doc) {
          let coupon = doc.data()
          // format
          if (coupon.start_date !== '' && typeof(coupon.start_date) === 'object') {
            const startDate = coupon.start_date.toDate()
            coupon.start_date = startDate.getFullYear() + '-' + ('0' + (startDate.getMonth() + 1)).slice(-2) + '-' + ('0' + startDate.getDate()).slice(-2)
          }
          if (coupon.end_date !== '' && typeof(coupon.end_date) === 'object') {
            const endDate = coupon.end_date.toDate()
            coupon.end_date = endDate.getFullYear() + '-' + ('0' + (endDate.getMonth() + 1)).slice(-2) + '-' + ('0' + endDate.getDate()).slice(-2)
          }

          // 有効化チェック
          coupon.is_valid = false
          if (this.isValid(coupon.id)) {
            coupon.is_valid = true
          }

          //let includeDistribution = coupon.distribution_ids != null && coupon.distribution_ids.includes(distributionId)
          //if (coupon.is_valid || includeDistribution) {
            //tmp.push(coupon)
          //}
          let includeUseDistribution = coupon.use_distribution_ids != null && coupon.use_distribution_ids.includes(distributionId)
          if (includeUseDistribution) {
            tmp.push(coupon)
          }
        }, this)
        this.coupons = tmp
      }.bind(this))
    },
    isValid(targetId) {
      const tmp = this.storeCoupons.find(coupon => coupon.id == targetId)
      return tmp !== undefined
    },
    isDateOverlap(startDate1, endDate1, startDate2, endDate2) {
      // 日付のみで比較する
      startDate1 = startDate1 != null ? new Date(startDate1.getFullYear(), startDate1.getMonth(), startDate1.getDate()) : null;
      endDate1 = endDate1 != null ? new Date(endDate1.getFullYear(), endDate1.getMonth(), endDate1.getDate()) : null;
      startDate2 = startDate2 != null ? new Date(startDate2.getFullYear(), startDate2.getMonth(), startDate2.getDate()) : null;
      endDate2 = endDate2 != null ? new Date(endDate2.getFullYear(), endDate2.getMonth(), endDate2.getDate()) : null;

      if (startDate1 == null) {
        if (endDate1 == null) return true;
        if (startDate2 == null) return true;
        return endDate1 >= startDate2;
      } else if (endDate1 == null) {
        if (endDate2 == null) return true;
        return startDate1 <= endDate2;
      } else if (startDate2 == null) {
        if (endDate2 == null) return true;
        return startDate1 <= endDate2 && endDate2 <= endDate1;
      } else if (endDate2 == null) {
        return startDate1 <= startDate2 && startDate2 <= endDate1;
      }
      return startDate1 <= endDate2 && startDate2 <= endDate1;
    },
    getMakerName(id) {
      const maker = this.makers.find(maker => maker.id === id)
      if (maker === undefined) {
        return ''
      } else {
        return maker.name
      }
    },
  },
  created () {
    this.init()
  },
  watch: {
    '$route': function () {
      this.init()
    }
  },
}
</script>
